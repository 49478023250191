import * as React from "react"
import DashboardCard from "./dashboardcard"

const Cards = ({cards, children}) => {
    return (
    <div class="grid grid-cols-1 lg:grid-cols-2 gap-10 px-8">
        {cards.map((item) => (
            <div class="py-8"> 
                <DashboardCard card={item}></DashboardCard>
            </div> 
        ))}
    </div>  
    )
  }

export default Cards