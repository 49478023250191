import * as React from "react"
import { Carousel } from 'react-carousel-minimal';

//https://reactjsexample.com/easy-to-use-responsive-and-customizable-carousel-component-for-react/
const DashboardCarousel =  ({ images, children }) => {
      const captionStyle = {
        fontSize: '2em',
        fontWeight: 'bold',
      }
      const slideNumberStyle = {
        fontSize: '20px',
        fontWeight: 'bold',
      }
      return (
        <div className="App">
            <div style={{
              padding: "0 0px"
            }}>
              <Carousel
                data={images}
                time={3000}
                width="850px"
                height="500px"
                captionStyle={captionStyle}
                radius="0px"
                slideNumber={false}
                slideNumberStyle={slideNumberStyle}
                captionPosition="bottom"
                automatic={true}
                dots={true}
                pauseIconColor="white"
                pauseIconSize="40px"
                slideBackgroundColor="darkgrey"
                slideImageFit="cover"
                thumbnails={false}
                thumbnailWidth="100px"
                style={{
                  textAlign: "center",
                  maxWidth: "850px",
                  maxHeight: "500px",
                  margin: "40px auto",
                }}
              />
          </div>
        </div>
      );
    }

export default DashboardCarousel