import * as React from "react"

const DashboardCard =  ({ card, children }) => {
    const Tekst = () => {
        return { __html: `${ card.text }` }
    }

    return (
        <div class="grid grid-cols-5 gap-8">
            <div class="col-span-2">
                {
                    card.link ?
                    <a href={card.link}>
                        <img class="object-scale-down rounded-md" src={card.image} alt="A kitten" />
                    </a> : 
                    <img class="object-scale-down rounded-md" src={card.image} alt="A kitten" />
                }
            </div>
            <div class="col-span-3">
                <h1 class="text-2xl font-bold ">
                    {card.link ? <a href={card.link}>{card.title}</a> : card.title}
                </h1>     
                <br></br>    
                <p class="text-md" dangerouslySetInnerHTML={Tekst()}></p> 
            </div>
            
        </div> 
    )
  }

export default DashboardCard