import * as React from "react"

const Sponsors = ({sponsors, children}) => {
    return (
    <div class="grid grid-cols-1 lg:grid-cols-2 gap-10 px-8">
        {sponsors.map(sponsor => (
            <img class="object-scale-down rounded-md" src={'/sponsors/'+sponsor} alt="Sponsor" />
        ))}
    </div>  
    )
  }

export default Sponsors