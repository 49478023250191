import * as React from "react"
import { graphql, useStaticQuery } from "gatsby"
import DashboardCarousel from "../components/carousel"
import Cards from "../components/dashboardcards"
import Sponsors from "../components/sponsors"
import Layout from "../components/layout"

const query = graphql`
query DashboardQuery {
  allFile(filter: {sourceInstanceName: {eq: "sponsors"}}) {
    edges {
      node {
        relativePath
      }
    }
  }

  allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/dashboard/"}}) {
    edges {
      node {
        frontmatter {
          events {
            image
            onlineFrom
            onlineUntil
            text
            title
            link
          }
          news {
            image
            onlineFrom
            onlineUntil
            text
            title
            link
          }
          images {
            image
            caption
          }
        }
      }
    }
  }
}
`


// markup
const IndexPage = () => {
  const data = useStaticQuery(query)
  const events = data.allMarkdownRemark.edges[0].node.frontmatter.events
  const sponsors = data.allFile.edges.map(sponsor => sponsor.node.relativePath)
  const news = data.allMarkdownRemark.edges[0].node.frontmatter.news
  const images = data.allMarkdownRemark.edges[0].node.frontmatter.images

  return (
    <Layout pageTitle={'Chiro Doeritsan'}>
      <DashboardCarousel images={images}></DashboardCarousel>

      <div class="my-20">
        <h1 class="mx-auto text-5xl max-w-fit font-bold">Nieuws</h1>
      </div>

      <Cards cards={news}></Cards>

      {events.length !== 0 && events[0].title &&
      <><div class="my-20">
          <h1 class="mx-auto text-5xl max-w-fit font-bold">Evenementen</h1>
        </div><Cards cards={events}></Cards></>
      }

      <><div class="my-20">
        <h1 class="mx-auto text-5xl max-w-fit font-bold">Sponsors</h1>
      </div><Sponsors sponsors={sponsors}></Sponsors></>
    </Layout>
  )
}
//https://github.com/rcaferati/react-awesome-slider 

export default IndexPage
